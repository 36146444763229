import MolhamButton from "./ReuseableCompos/MolhamButton";

type Props = {
  icon?: string;
  title?: string;
  desc?: string;
  action: {
    label: string;
    onClick: () => void;
    style?: React.CSSProperties;
    labelClassName?: string;
    variant?: string;
    href?: string;
  };

  background?: string;
};

function HomePageActionCard({ action, background, desc, icon, title }: Props) {
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        backgroundColor: background,
        padding: 64,
        gap: 40,
        borderRadius: 24,
        position: "relative",
        width: "100%",
        flex: 1,
        overflow: "hidden",
        height: "100%",
      }}
    >
      <SvgComponent />
      <i
        className={`fe-${icon} text-white-always`}
        style={{ fontSize: 64 }}
      ></i>
      <div
        className="text-white-always"
        style={{ fontSize: 40, fontWeight: 700, lineHeight: "48px" }}
      >
        {title}
      </div>
      <div
        className="text-white-always"
        style={{
          fontSize: 16,
          fontWeight: 400,
          lineHeight: "28px",
        }}
      >
        {desc}
      </div>
      <MolhamButton
        onClick={() => action?.onClick()}
        className="w-100 text-white-always"
        id={"home-page-action-card-btn" + title}
        label={action.label}
        style={action?.style}
        labelClassName={action.labelClassName}
        variant={(action?.variant as any) ?? "primary"}
        href={action?.href}
      />
    </div>
  );
}

export default HomePageActionCard;
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={327}
    height={266}
    fill="none"
    style={{
      top: "-8px",
      left: "-16px",
      position: "absolute",
    }}
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.04}
      d="M-35.214 142.669s102.587 13.8 104.1 122.764c0 0-89.51-5.367-104.1-122.764ZM65.229 161.844s70.574 9.493 71.615 84.454c0 0-61.578-3.692-71.615-84.454ZM65.82 72.045s77.715 10.454 78.862 92.999c0 0-67.808-4.066-78.861-92.999ZM73.992-9.63s56.978 7.664 57.818 68.183c0 0-49.714-2.981-57.818-68.184ZM-11.008 79.37s56.977 7.663 57.818 68.183c0 0-49.714-2.981-57.818-68.184ZM-7.008-9.63S49.97-1.967 50.81 58.552c0 0-49.714-2.981-57.818-68.184ZM151.353 91.143s77.714 10.454 78.861 92.999c0 0-67.808-4.066-78.861-93ZM156.353.143s77.714 10.454 78.861 92.999c0 0-67.808-4.066-78.861-93ZM247.353 11.143s77.714 10.454 78.861 92.999c0 0-67.808-4.066-78.861-93Z"
    />
  </svg>
);
