import Link from "next/link";

import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { TUtilities } from "../customHooks/useUtilities";
import { useAllTextColors } from "../layout/Layout";
import HomeSlider from "./Home/HomeSlider";
import HomeCardsSlider from "./HomeCardsSlider";
import DonateCardHome from "./ReuseableCompos/SharedDonatePages/DonateCardHome";
import SvgCreator from "./ReuseableCompos/SvgCreator";

import { MolhamListTargetable, TBlog, THomeWidget } from "apiTypes";
import HomeIntroSlider from "components/Home/HomeIntroSlider";
import SvgCreatorBackup from "components/ReuseableCompos/SvgCreatorBackup";
import { NEXT_URL_PROXY } from "config";
import { useAuthContext } from "context/AuthContext";
import useMobile from "customHooks/useMobile";
import useModal from "customHooks/useModal";
import Image from "next/image";
import { useState } from "react";
import { toast } from "react-toastify";
import { postData } from "utils/postData";
import NewsLetterModal from "./Donate/NewsLetterModal";
import HomePageActionCard from "./HomePageActionCard";
import SEO from "./Seo";
type Props = {
  data: THomeWidget[];
  quick_actions: TUtilities["quick_actions"];
  promoVid?: string;
};
const HomePage = ({ data, quick_actions, promoVid }: Props) => {
  const { locale, push } = useRouter();
  const { donor } = useAuthContext();
  const { t } = useTranslation("common");

  const onCardClick = (id) => {
    push(`${id}`);
  };
  const isMobile = useMobile("md");
  const innercontStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
  };
  const contStyle = {
    padding: 0,
  };
  const allColors = useAllTextColors();
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const onEventSubmit = async (data) => {
    setIsLoading(true);
    const res = await postData(`${NEXT_URL_PROXY}/newsletter-subscribers`, {
      ...data,
    });
    if (res.status === 200) {
      modal.handleCloseModal();
      toast.success(t("subbed_to_newsletter"));
    } else {
      toast.error(t("something_went_wrong"));
    }
    setIsLoading(false);
  };
  const isBackup = false;

  return (
    <>
      <SEO />
      <HomeIntroSlider promoVid={promoVid} />
      <div className="centered flex-column w-100 my-5">
        <Image
          alt="logo"
          loading="eager"
          src={"/img/logo/logo-1.png"}
          width={isMobile ? 200 : 250}
          height={isMobile ? 200 : 250}
        />
        <div
          style={{
            width: 50,
            background: "var(--primary2)",
            height: 2,
          }}
          className="my-3"
        />
        <h4
          className="text-white text-center fw-normal lh17 "
          style={{ maxWidth: isMobile ? "85vw" : "60vw" }}
        >
          {t("who_we_are")}
        </h4>
        <div className="position-relative">
          <div
            id={"campaigns"}
            className="position-absolute"
            style={{ top: -100 }}
          />
        </div>
      </div>
      <div
        className={`container text-decoration-0`}
        style={isMobile ? contStyle : {}}
      >
        <div className={`testimonial-group ${isMobile ? "mb-2 mt-4" : "my-4"}`}>
          <div
            className="d-flex align-items-center w-100 px-2 px-lg-0 scaleRightAndBack"
            style={{ overflowX: "scroll", overflowY: "hidden" }}
          >
            {quick_actions.map((item) => {
              const isProtectedPath =
                item?.path?.web?.includes("event") && !donor;
              return (
                <div
                  key={item.path.web}
                  style={{
                    minWidth: "86px",
                    minHeight: "122px",
                    width: isMobile ? "" : "122px",
                  }}
                >
                  <Link
                    href={isProtectedPath ? "#login" : item?.path?.web}
                    aria-label={t(item?.name[locale])}
                    className="mx-1 d-flex flex-column align-items-center text-decoration-none"
                    style={{ color: "unset" }}
                  >
                    <div className="rounded-circle bg-squircle">
                      {isBackup ? (
                        <SvgCreatorBackup
                          color={allColors[item?.icon?.color]}
                          uri={item?.icon?.uri}
                        />
                      ) : (
                        <SvgCreator
                          color={allColors[item?.icon?.color]}
                          uri={item?.icon?.uri}
                        />
                      )}
                    </div>
                    <span
                      className={`${
                        isMobile ? "fs-xs" : "fs-sm"
                      } mt-2 text-center fw-bold w-100`}
                      style={{
                        maxWidth: "90%",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        color: "unset",
                      }}
                    >
                      {t(item?.name[locale])}
                    </span>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className="">
          {data.map((group, k) => {
            if (group.type === "slider") {
              return (
                <div
                  key={k}
                  dir="ltr"
                  className={`rounded  ${isMobile ? "mx-3 mb-5" : "mb-4"}`}
                >
                  <HomeSlider slides={group.slides} />
                </div>
              );
            }
            return isMobile ? (
              <div key={k} className={`testimonial-group`}>
                <div className="d-flex w-100 align-items-center mb-3 justify-content-between">
                  <h4 className={`mb-0 fs-lg fw-bolder mx-4`}>
                    {group?.title}
                  </h4>
                  <button
                    className={`btn btn-secondary py-0 px-2 fs-sm border-none fw-bold rounded-xs mx-4`}
                    onClick={() => {
                      push(group.more_path.web.split("/")[1]);
                    }}
                  >
                    {t("more")}
                  </button>
                </div>
                <div
                  className={`pe-3 my-3 `}
                  style={isMobile ? innercontStyle : {}}
                >
                  {(group.type === "blog_posts_cards"
                    ? group.blog_posts
                    : group.targets
                  )?.map((item: TBlog | MolhamListTargetable, i) => {
                    return (
                      <DonateCardHome
                        key={i}
                        data={item}
                        onCardClick={onCardClick}
                        noBtn
                        noDoubleSlash
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div key={k} className="mt-3 mb-4">
                <HomeCardsSlider group={group} />
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="w-100  gap-5 container mb-3"
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
        }}
      >
        <HomePageActionCard
          title={t("contact_us")}
          desc={t("contact_us_desc")}
          background={"var(--primary2)"}
          icon={"phone"}
          action={{
            label: t("see_contact_options"),
            style: {
              backgroundColor: "#fff",
              color: "var(--primary2)",
              borderColor: "#fff",
            },
            labelClassName: "text-dark",
            variant: "light",
            href: "/help/contact-us",
            onClick: () => {},
          }}
        />
        <HomePageActionCard
          title={t("sub_to_newsletter")}
          desc={t("newsletter_desc")}
          background={"#041A2F"}
          icon={"mail"}
          action={{
            onClick: modal.handleShowModal,
            label: t("subscribe"),
          }}
        />
        <NewsLetterModal
          closeModalOffCanvas={modal.handleCloseModal}
          showModalOffcanvas={modal.showModal}
          onSubmit={onEventSubmit}
          isAdd
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default HomePage;
