import { yupResolver } from "@hookform/resolvers/yup";
import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import MolhamInput from "components/ReuseableCompos/MolhamInput";
import { useModeContext } from "context/ModeContext";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from "yup";
type Props = {
  closeModalOffCanvas: () => void;
  showModalOffcanvas: boolean;
  isAdd?: boolean;
  content?: string;
  onSubmit: (data: any) => void;
  isLoading?: boolean;
};

function NewsLetterModal({
  closeModalOffCanvas,
  showModalOffcanvas,
  content,
  onSubmit,
}: Props) {
  const { t } = useTranslation("common");

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t(`email_required`)).email(t(`email_invalid`)),
    name: Yup.string().required(t(`name_required`)),
  });
  const { handleSubmit, reset, control, watch } = useForm<{
    email: string;
    name: string;
  }>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const [captcha, setCaptcha] = useState<string | null>(null);

  const onFormSubmit = async (data) => {
    if (!captcha) {
      toast.error(t(`captcha_required`));
      return;
    }
    onSubmit(data);
    reset();
    close ? close() : null;
  };
  const { theme } = useModeContext();
  const onload = () => {
    const doc = document.getElementById("captcha-parent")?.childNodes[0]
      .childNodes[0].childNodes[0] as HTMLDivElement;

    doc.style.width = "302px";
  };
  return (
    <MolhamModalOffcanvas
      onHide={closeModalOffCanvas}
      header={{ label: "sub_to_newsletter" }}
      show={showModalOffcanvas}
      swipeToClose={false}
      fullscreen
    >
      <form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <MolhamInput
          control={control}
          watch={watch}
          name="name"
          placeholder={t(`name`)}
          type="text"
        />
        <MolhamInput
          control={control}
          watch={watch}
          name="email"
          // label={t(`email`)}
          placeholder={t(`email`)}
          type="email"
        />
        <div className="mb-3 centered" id="captcha-parent">
          <ReCAPTCHA
            sitekey={"6LcFR8MpAAAAALLLZOkuImCcZ_zcU260rSz-kj4I"}
            onChange={(v) => setCaptcha(v)}
            onErrored={() => setCaptcha(null)}
            onExpired={() => setCaptcha(null)}
            theme={theme === "dark" ? "dark" : "light"}
            asyncScriptOnLoad={onload}
            style={{
              width: 302,
              height: 76,
              borderRadius: 3,
              overflow: "hidden",
            }}
          />
        </div>
        <MolhamButton
          id="support-issue-submit-btn"
          label={t("subscribe")}
          className="w-100"
        />
      </form>
    </MolhamModalOffcanvas>
  );
}

export default NewsLetterModal;
