import { EducationSVG } from "components/MolhamSvgIcons/EducationSVG";
import { EventsSVG } from "components/MolhamSvgIcons/EventsSVG";
import { HumanitarianSVG } from "components/MolhamSvgIcons/HumanitarianSVG";
import { MedicalSVG } from "components/MolhamSvgIcons/MedicalSVG";
import { OrphansSVG } from "components/MolhamSvgIcons/OrphansSVG";
import { QuickSVG } from "components/MolhamSvgIcons/QuickSVG";
import { ResponseSVG } from "components/MolhamSvgIcons/ResponseSVG";
import { ShelterSVG } from "components/MolhamSvgIcons/ShelterSVG";
import useMobile from "customHooks/useMobile";
type Props = { uri: string; color: string };

function SvgCreatorBackup({ uri, color }: Props) {
  const isMobile = useMobile("md");
  const Icon = IconCreator(uri);
  return (
    <div style={{ width: isMobile ? 64 : 72, padding: 16 }}>
      <Icon fill={color} />
    </div>
  );
}

export default SvgCreatorBackup;
const IconCreator = (uri: string) => {
  //medical , events , humanitarian , education ,quick_actions , response , shelter , orphans
  if (uri.includes("medical")) {
    return MedicalSVG;
  } else if (uri.includes("events")) {
    return EventsSVG;
  } else if (uri.includes("humanitarian")) {
    return HumanitarianSVG;
  } else if (uri.includes("education")) {
    return EducationSVG;
  } else if (uri.includes("quick_actions")) {
    return QuickSVG;
  } else if (uri.includes("response")) {
    return ResponseSVG;
  } else if (uri.includes("shelter")) {
    return ShelterSVG;
  } else if (uri.includes("orphans")) {
    return OrphansSVG;
  } else {
    return QuickSVG;
  }
  // else if (uri.includes)
};
