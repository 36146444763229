import { MolhamListTargetable, TBlog } from "apiTypes";
import useMobile from "customHooks/useMobile";
import { nextCheckoutItemSourceManager } from "customHooks/useNextCheckoutItemSource";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { memo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { NEXT_URL_PROXY, tragetableFallbackImg } from "../../../config";
import { deleteData } from "../../../utils/deleteData";
import { postData } from "../../../utils/postData";
import DonateCardSkeleton from "./DonateCardSkeleton";
type Props = {
  data?: TBlog | MolhamListTargetable;
  handleShowShareModal?: () => void;
  skeleton?: boolean;
  onCardClick?: (id: string) => void;
  noBtn?: boolean;
  noDoubleSlash?: boolean;
};

const DonateCardHome = ({
  data,
  handleShowShareModal = () => {},
  skeleton = false,
  onCardClick,
  noBtn,
  noDoubleSlash,
}: Props) => {
  const router = useRouter();
  const isTargetable = "path" in data;
  const { preview_image, id } = data;
  const path = isTargetable ? data?.path : "blog/" + id;
  const [isItemSaved, setIsItemSaved] = useState(null);
  const onShareIconClick = () => {
    handleShowShareModal();
  };

  const onDonateBtnClick = () => {
    if (isTargetable) {
      router.push(`${router.asPath}/${path}`);
    }
  };
  const parentRoute = router.asPath.split("/")[1];
  const onSavedItem = async () => {
    try {
      await postData(`${NEXT_URL_PROXY}/saved_items`, {
        saveable_type: parentRoute,
        saveable_id: id,
      });
      setIsItemSaved(true);
      toast.success(`saved_item`);
    } catch (error) {
      setIsItemSaved(false);
    }
  };
  const onUnSavedItem = async () => {
    try {
      await deleteData(`${NEXT_URL_PROXY}/saved_items/${path}`);
      setIsItemSaved(false);
      toast.success(`unsaved_item`);
    } catch (error) {
      setIsItemSaved(true);
    }
  };

  const isMobile = useMobile("md");
  const onLinkClick = () => {
    nextCheckoutItemSourceManager.setNextCheckoutItemSource(
      isTargetable
        ? nextCheckoutItemSourceManager.options.target_cards_widget
        : nextCheckoutItemSourceManager.options.blog_post
    );
  };
  if (skeleton) {
    return (
      <DonateCardWrapper className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-grid-gutter">
        <DonateCardSkeleton />
      </DonateCardWrapper>
    );
  }
  return (
    <DonateCardWrapper
      onClick={() => {
        onLinkClick();
        onCardClick(path);
      }}
      className={
        isMobile
          ? "col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-grid-gutter"
          : ""
      }
      style={isMobile ? mobileStyles.wrapper : { width: "300px" }}
    >
      <div
        className="card card-product  card-hover  rounded border"
        style={
          isMobile ? mobileStyles.card : { minWidth: "100%", height: "380px" }
        }
      >
        <Link
          onClick={onLinkClick}
          prefetch={false}
          href={`${router.asPath.split("?")[0]}${
            noDoubleSlash ? "" : "/"
          }${path}`}
          className="card-img-top"
        >
          <div
            className="donate-card"
            style={{
              height: isMobile ? mobileStyles.imgHeight : 224,
              width: "100%",
            }}
          >
            <Image
              // height={isMobile ? mobileStyles.imgHeight : 224}
              // width={isMobile ? mobileStyles.imgWidth : 300}
              height={512}
              width={512}
              className="object-fit-cover"
              src={preview_image?.thumbnails?.["1:1"] || tragetableFallbackImg}
              alt={data.contents?.title}
            />
          </div>
        </Link>
        <div
          className="card-body pb-0 d-flex flex-column align-items-center"
          style={isMobile ? mobileStyles.cardBody : {}}
        >
          <span
            className={`h5 mb-1 ${!isMobile && "py-3"}`}
            style={{ width: "fit-content", maxWidth: "100%" }}
          >
            <Link
              onClick={onLinkClick}
              prefetch={false}
              href={`${router.asPath}${noDoubleSlash ? "" : "/"}${path}`}
              style={isMobile ? mobileStyles.cardHeader : {}}
              className={`text-primary text-decoration-0 ${
                !isMobile ? " text-break meta-link adjust-text-1-col " : "fs-md"
              }`}
            >
              {data.contents?.title}
            </Link>
          </span>
          <h3
            style={isMobile ? mobileStyles.cardDesc : {}}
            className={`${
              isMobile
                ? "fs-xs text-muted fw-light"
                : "fs-md fw-medium meta-title "
            } pb-4 mb-0 mt-2 w-100 lh17 text-gray`}
          >
            <Link
              onClick={onLinkClick}
              prefetch={false}
              href={`${router.asPath}${noDoubleSlash ? "" : "/"}${path}`}
              style={isMobile ? mobileStyles.cardDesc : {}}
              className={`text-gray text-decoration-0 lh17 ${
                !isMobile && "meta-link"
              }`}
            >
              {isTargetable
                ? data.contents?.description
                : data.contents?.raw_body}
            </Link>
          </h3>
        </div>
      </div>
    </DonateCardWrapper>
  );
};

export default memo(DonateCardHome);
const DonateCardWrapper = styled.div`
  .card-product {
    position: relative;
    max-width: 25rem;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);
  }
  .meta-link {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .save-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
  }
`;

const mobileStyles = {
  wrapper: {
    width: "150px",
    height: "268px",
    marginInlineEnd: "16px",
  },
  card: {
    width: "150px",
    height: "268px",
    border: "none !important",
  },
  cardBody: {
    padding: "0px 8px",
  },
  cardHeader: {
    maxWidth: "100%",
    textAlign: "start",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardDesc: {
    maxWidth: "100%",
    textAlign: "start",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  imgHeight: 148,
  imgWidth: 148,
} as const;
