import { TMolhamImage } from "apiTypes";
import { useModeContext } from "context/ModeContext";
import useMobile from "customHooks/useMobile";
import { nextCheckoutItemSourceManager } from "customHooks/useNextCheckoutItemSource";
import useRTL from "customHooks/useRTL";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { A11y, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
type Props = {
  slides: {
    image: TMolhamImage;
    path: {
      web: string;
    };
  }[];
};
const HomeSlider: React.FC<Props> = ({ slides }) => {
  const isMobile = useMobile("md");
  const { theme } = useModeContext();
  const isRTL = useRTL();
  const { push } = useRouter();

  return (
    <>
      <StyledSwiper
        key={isRTL.toString()}
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={20}
        slidesPerView={isMobile ? 1 : 2.25}
        navigation
        dir={isRTL ? "rtl" : "ltr"}
        pagination={{
          clickable: true,
        }}
        theme={theme}
      >
        {slides.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              onClick={() => {
                push(item.path.web);
              }}
              className="cursor"
            >
              <Link
                onClick={() => {
                  nextCheckoutItemSourceManager.setNextCheckoutItemSource(
                    nextCheckoutItemSourceManager.options.slider_widget
                  );
                }}
                href={item.path.web}
              >
                <Image
                  priority={index <= (isMobile ? 0 : 1)}
                  width={768}
                  height={432}
                  sizes="(max-width : 768px) 100vw, 50vw"
                  className={"image rounded"}
                  src={item.image.thumbnails["16:9"]}
                  alt={item.path.web}
                />
              </Link>
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </>
  );
};

export default HomeSlider;

const StyledSwiper = styled(Swiper)`
  .swiper-pagination {
    width: 100%;
    text-align: center !important;
    bottom: 0 !important;
    @media (max-width: 768px) {
      bottom: 12px !important;
    }
  }
  .swiper-wrapper {
    padding-bottom: 48px !important;
    @media (max-width: 768px) {
      padding-bottom: 0px !important;
    }
  }

  .swiper-pagination-bullet {
    background: ${({ theme }) =>
      theme === "light" ? "var(--primary2)" : "#fff"} !important;
    margin-inline: 8px !important;
    opacity: 1 !important;
    @media (max-width: 767.98px) {
      background: #fff !important;
    }
  }
  .swiper-pagination-bullet-active {
    background: transparent !important;
    border: 1px solid
      ${({ theme }) => (theme === "light" ? "var(--primary2)" : "#fff")} !important;
    transform: scale(2) !important;
    @media (max-width: 767.98px) {
      background: transparent !important;
      border: 1px solid #fff !important;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 148px !important;
    background: #fff !important;
    border-radius: 25% !important;
    width: 50px !important;
    height: 50px !important;
    @media (max-width: 767.98px) {
      display: none !important;
    }
  }
  .swiper-button-prev::after {
    margin-inline-end: 4px !important;
  }

  .swiper-button-next {
    margin-inline-start: 4px !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: var(--primary2) !important;

    font-size: 25px !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }
`;
