import { THomeWidget } from "apiTypes";
import DonateCardHome from "components/ReuseableCompos/SharedDonatePages/DonateCardHome";
import { useModeContext } from "context/ModeContext";
import useMobile from "customHooks/useMobile";
import { nextCheckoutItemSourceManager } from "customHooks/useNextCheckoutItemSource";
import useRTL from "customHooks/useRTL";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useState } from "react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { A11y, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
type Props = { group: THomeWidget };

function HomeCardsSlider({ group }: Props) {
  const { theme } = useModeContext();
  const { t } = useTranslation("common");
  const isRTL = useRTL();
  const { push } = useRouter();

  const onLinkClick = () => {
    nextCheckoutItemSourceManager.setNextCheckoutItemSource(
      group.type === "target_cards"
        ? nextCheckoutItemSourceManager.options.target_cards_widget
        : group.type === "blog_posts_cards"
        ? nextCheckoutItemSourceManager.options.blog_post
        : undefined
    );
  };
  const onCardClick = (id) => {
    onLinkClick();
    push(`${id}`);
  };
  const [swiper, setSwiper] = useState(null);
  const goNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  const breakpoint1 = useMobile("1240");
  const breakpoint2 = useMobile("1080");
  const breakpoint3 = useMobile("900");
  const bps = { slidesPerView: 3.75 };
  if (breakpoint1) {
    bps.slidesPerView = 3.25;
  }
  if (breakpoint2) {
    bps.slidesPerView = 2.75;
  }
  if (breakpoint3) {
    bps.slidesPerView = 2.3;
  }
  if (!breakpoint1 && !breakpoint2 && !breakpoint3) {
    bps.slidesPerView = 3.75;
  }

  if (swiper) {
    swiper.params.slidesPerView = bps.slidesPerView;
    swiper.update();
  }

  return (
    <Container className="">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className={`mb-0  fw-bolder mx-3 `}>{group?.title}</h4>

        <div className="d-flex align-items-center ">
          <div className="btn-group" dir="ltr">
            <div
              className={`btn btn-outline-primary px-2 py-1 ${
                theme === "dark" && "btn-secondary"
              }`}
              onClick={!isRTL ? goPrev : goNext}
            >
              <span>
                <i
                  className={`fs-md fe fe-chevron-${isRTL ? "right" : "left"}`}
                  dir="ltr"
                ></i>
              </span>
            </div>
            <div
              className={`btn btn-outline-primary px-2 py-1 ${
                theme === "dark" && "btn-secondary"
              }`}
              onClick={!isRTL ? goNext : goPrev}
            >
              <span>
                <i
                  className={`fs-md fe fe-chevron-${isRTL ? "left" : "right"}`}
                  dir="ltr"
                ></i>
              </span>
            </div>
          </div>

          <button
            className={`btn btn-outline-primary  px-3 py-1   mx-3`}
            onClick={() => {
              onLinkClick();
              push(group.more_path.web.split("/")[1]);
            }}
          >
            <span className="">{t("more")}</span>
          </button>
        </div>
      </div>
      <StyledSwiper
        key={isRTL.toString()}
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={20}
        slidesPerView={3.8}
        navigation
        dir={isRTL ? "rtl" : "ltr"}
        pagination={{
          clickable: true,
        }}
        theme={theme}
        onSwiper={setSwiper}
      >
        {group?.[
          group.type === "blog_posts_cards" ? "blog_posts" : "targets"
        ].map((item, idx) => {
          return (
            <SwiperSlide key={idx}>
              <DonateCardHome
                data={item}
                onCardClick={onCardClick}
                noBtn
                noDoubleSlash
              />
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </Container>
  );
}

export default HomeCardsSlider;
const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    width: fit-content !important;
  }
  .swiper-pagination {
    width: 100%;
    text-align: center !important;
    bottom: 0 !important;
    @media (max-width: 768px) {
      bottom: 12px !important;
    }
  }
  .swiper-wrapper {
    padding-bottom: 48px !important;
    @media (max-width: 768px) {
      padding-bottom: 0px !important;
    }
  }

  .swiper-pagination-bullet {
    background: ${({ theme }) =>
      theme === "light" ? "var(--primary2)" : "#fff"} !important;
    margin-inline: 8px !important;
    opacity: 1 !important;
    @media (max-width: 767.98px) {
      background: #fff !important;
    }
  }
  .swiper-pagination-bullet-active {
    background: transparent !important;
    border: 1px solid
      ${({ theme }) => (theme === "light" ? "var(--primary2)" : "#fff")} !important;
    transform: scale(2) !important;
    @media (max-width: 767.98px) {
      background: transparent !important;
      border: 1px solid #fff !important;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
  .swiper-button-prev::after {
    margin-inline-end: 4px !important;
  }

  .swiper-button-next {
    margin-inline-start: 4px !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: var(--primary2) !important;

    font-size: 25px !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }
`;
const Container = styled.div`
  .btn {
    color: var(--primary2) !important;
  }
  .btn:hover {
    color: #fff !important;
  }
`;
